import React, {
  ReactElement,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import logo from '../images/dStor.svg';
import { SignIn } from './SignIn';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { UserMenu } from './UserMenu';
import { Pulse } from './Pulse';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import SideMenu from './SideMenu';
import { Heading } from './Heading';
import { Button } from './Button';
import { TextInput } from './TextInput';
import { ServerError } from './ServerError';
import { callApi } from '../functions/callApi';
import { FileInput } from './FileInput';
import { TopNav } from './TopNav';
import { useCookies } from 'react-cookie';
import { TopNagBar } from './TopNagBar';

export interface UploadErrorShape {
  error: string;
  message: string;
}
import useFilesTransfer from '../hooks/useFilesTransfer';

declare global {
  interface Window {
    gtag: any;
  }
}

interface PropsShape {
  setSelectedPage?: Dispatch<SetStateAction<number>>;
}

const Header = ({ setSelectedPage }: PropsShape): ReactElement => {
  const location = useLocation();
  const params = useParams();
  const paramsArr = params['*']?.split('/') || [];
  const paramsKey = paramsArr[paramsArr.length - 1] || '';
  const navigate = useNavigate();
  const { userState, setUserState } = useGlobalUserState();
  const [searchText, setSearchText] = useState('');
  const [isDirectoryOpen, setIsNewDirectoryOpen] = useState<boolean>(false);
  const [directoryName, setDirectoryName] = useState<string>('');
  const [directoryDescription, setDirectoryDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [isUnpaidInvoiceBarOpen, setIsUnpaidInvoiceBarOpen] =
    useState<boolean>(false);
  const [cardOnFile, setCardOnFile] = useState<boolean>(false);
  const [isOrgMenuOpen, setIsOrgMenuOpen] = useState<boolean>(false);
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<string>('');
  const [menuName, setMenuName] = useState<string>('');
  const [isBillingInfoAllowed, setIsBillingInfoAllowed] =
    useState<boolean>(true);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
  const unpaidInvoice = userState?.data?.unpaidInvoice;
  const [, , removeCookie] = useCookies(['userState']);
  const [cookie] = useCookies(['webAuthn']);
  const [isOpenExistingFilesModal, setIsOpenExistingFilesModal] =
    useState<boolean>(false);
  const {
    uploading,
    doUpload,
    uploadedBatches,
    numberOfBatches,
    uploadAbortMessage,
    uploadErrorMessage,
    uploadError,
    filesToUpload,
    batchSize,
    batchesToUpload,
    isProgressValid,
    progress,
    serverError,
    uploadLoading,
    existingFilesName,
  } = useFilesTransfer();

  useEffect(() => {
    if (window && window.gtag) {
      window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
        page_path: location.pathname,
      });
    }
  }, [location]);

  useEffect(() => {
    if (userState.currentOrganization && !userState.data?.isActive) {
      setCardOnFile(
        userState.data?.organizations?.find((org: any) => org?.is_active)
          ?.organization_data?.has_credit_card || false
      );

      if (
        userState.data?.organizations &&
        userState.data?.organizations.length > 0
      ) {
        const orgRole = userState.data?.organizations.find(
          (org: any) => org.is_active
        );
        setIsBillingInfoAllowed(
          orgRole?.role === 'owner' ||
            orgRole?.role === 'billing' ||
            orgRole?.role === 'manager'
        );
      }
    } else {
      setCardOnFile(userState?.data?.has_credit_card || false);
      setIsBillingInfoAllowed(true);
    }
  }, [userState.currentOrganization, userState.data?.isActive]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const firstPath = location.pathname.split('/')[1];
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        canUpload: firstPath === 'files',
      },
    }));
  }, [location]);

  useEffect(() => {
    if (!isDirectoryOpen) {
      setDisabled(true);
      setDirectoryName('');
      setDirectoryDescription('');
    }
  }, [isDirectoryOpen]);

  useEffect(() => {
    directoryName && setDisabled(false);
  }, [directoryName]);

  useEffect(() => {
    if (uploadAbortMessage) {
      // eslint-disable-next-line no-console
      console.log('uploadAbortMessage: ' + uploadAbortMessage);
    }
  }, [uploadAbortMessage]);

  useEffect(() => {
    if (uploadErrorMessage) {
      // eslint-disable-next-line no-console
      console.log('uploadErrorMessage: ' + uploadErrorMessage);
    }
  }, [uploadErrorMessage]);

  useEffect(() => {
    if (uploadedBatches === numberOfBatches) setIsUploadOpen(false);
  }, [uploadedBatches]);

  useEffect(() => {
    if (unpaidInvoice) {
      setIsUnpaidInvoiceBarOpen(true);
    } else {
      setIsUnpaidInvoiceBarOpen(false);
    }
  }, [unpaidInvoice]);

  useEffect(() => {
    if (userState.data?.isActive) {
      setMenuName('');
    } else {
      setMenuName(userState.currentOrganization?.name || '');
    }
  }, [
    selectedOrganizationId,
    userState.data,
    userState.currentOrganization?.name,
  ]);

  useEffect(() => {
    if (serverError) setError(serverError);
  }, [serverError]);

  useEffect(() => {
    setLoading(uploadLoading);
  }, [uploadLoading]);

  useEffect(() => {
    if (existingFilesName.length > 0) setIsOpenExistingFilesModal(true);
  }, [existingFilesName]);

  const doSearch = (e: any): void => {
    e.keyCode === 13 && navigate(`/search?q=${searchText}`);
  };

  const createFolder = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await callApi<any>(
        'folder',
        'POST',
        JSON.stringify({
          comment: directoryDescription,
          name: directoryName,
          parent: paramsKey,
        })
      );
      !result?.success && setError(result.message);
      result?.created && setIsNewDirectoryOpen(false);
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          lastUpload: Date.now(),
        },
      }));
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const signOut = async (): Promise<void> => {
    const parsedUrl = new URL(
      process.env.REACT_APP_FRONT_END_URL
        ? process.env.REACT_APP_FRONT_END_URL
        : ''
    );
    const domain = parsedUrl.hostname;
    removeCookie('userState', {
      path: '/',
      domain,
    });
    if (userState?.currentOrganization?.accountKey)
      await handleOrganizationSelect(
        userState.currentOrganization.accountKey,
        true
      );
    setUserState((prevState: any) => ({
      ...prevState,
      currentOrganization: null,
      data: {
        ...prevState.data,
        apiKeys: null,
        fileUsage: null,
        loggedIn: false,
        usage: null,
        pulse: null,
      },
    }));

    navigate('/');
  };

  const handleOrganizationSelect = async (
    organizationId: string,
    isUserAccount: boolean
  ): Promise<void> => {
    try {
      setUserState((prevState: any) => ({
        ...prevState,
        isSwitchingAccount: true,
      }));
      const response = await callApi<any>(
        `organizations/select_active`,
        'POST',
        JSON.stringify({
          organization_id: organizationId,
          isUserAccount,
        })
      );

      if (response && response.status === 200) {
        const updatedOrganizations = userState?.data?.organizations?.map(
          (org: any) => {
            const updatedOrg = response.organizations.find(
              (o: any) =>
                o.organization_id === org.organization_data?.account_key
            );
            if (updatedOrg) {
              return { ...org, is_active: updatedOrg.is_active };
            } else {
              return { ...org, is_active: false };
            }
          }
        );

        setUserState((prevState: any) => ({
          ...prevState,
          data: {
            ...prevState.data,
            isActive: !!isUserAccount,
            organizations: updatedOrganizations,
          },
          isSwitchingAccount: false,
          currentOrganization: isUserAccount
            ? null
            : {
                ...prevState.currentOrganization,
                maxThreshold:
                  response.currentOrganization.maximum_threshold_usd,
                isPreviewOn: response.currentOrganization.is_preview_on,
                accountKey: response.currentOrganization.account_key,
                accessToken: response.currentOrganization.access_token,
                name: response.currentOrganization.owner_name,
                email: response.currentOrganization.owner_email,
                encryption: {
                  publicKey: response.currentOrganization.rsa_pub_key,
                  selfHint: response.currentOrganization.self_hint,
                },
                lastUpload: Date.now(),
                isNodeOperator: response.currentOrganization.has_nodes,
                has_credit_card: response.currentOrganization.has_credit_card,
                has_nodes: response.currentOrganization.has_nodes,
                userRole:
                  response.currentOrganization.account_role?.type || null,
                pulse: {
                  accountDeletedMail:
                    response.currentOrganization.account_deleted_mail,
                  accountDeletedPulse:
                    response.currentOrganization.account_deleted_pulse,
                  accountFrozenMail:
                    response.currentOrganization.account_frozen_mail,
                  accountFrozenPulse:
                    response.currentOrganization.account_frozen_pulse,
                  failedPaymentMail:
                    response.currentOrganization.failed_payment_mail,
                  failedPaymentPulse:
                    response.currentOrganization.failed_payment_pulse,
                  invoiceGeneratedMail:
                    response.currentOrganization.invoice_generated_mail,
                  invoiceGeneratedPulse:
                    response.currentOrganization.invoice_generated_pulse,
                  noPaymentMethodMail:
                    response.currentOrganization.no_payment_method_mail,
                  noPaymentMethodPulse:
                    response.currentOrganization.no_payment_method_pulse,
                  successfulPaymentMail:
                    response.currentOrganization.successful_payment_mail,
                  successfulPaymentPulse:
                    response.currentOrganization.successful_payment_pulse,
                  thresholdNotReachedMail:
                    response.currentOrganization.threshold_not_reached_mail,
                  thresholdNotReachedPulse:
                    response.currentOrganization.threshold_not_reached_pulse,
                },
                unpaidInvoice: response.currentOrganization.unpaid_invoice,
              },
        }));
        setSelectedOrganizationId(organizationId);
        if (setSelectedPage) {
          setSelectedPage(1);
        }
        navigate(userState.data?.loggedIn ? '/files' : '/');
      } else if (response.message) {
        // eslint-disable-next-line no-console
        console.error('Error:', response.message);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    }
  };

  const getPublicKeyForCurrOrg = async (): Promise<void> => {
    try {
      const response = await callApi<any>(
        `encryption/public-key/$isOrganization=${true}`,
        'GET'
      );
      if (response.status === 200) {
        if (
          userState.currentOrganization?.encryption?.publicKey !==
          response.rsa_pub_key
        ) {
          setUserState((prevState: any) => ({
            ...prevState,
            currentOrganization: {
              ...prevState.currentOrganization,
              encryption: {
                publicKey: response.rsa_pub_key,
                selfHint: response.self_hint,
              },
            },
          }));
        }
      } else if (response.status === 404) {
        setUserState((prevState: any) => ({
          ...prevState,
          currentOrganization: {
            ...prevState.currentOrganization,
            encryption: {},
          },
        }));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleUserMenuClick = (path: string): void => {
    if (location.pathname === path) {
      setIsUserMenuOpen(false);
    } else {
      navigate(path);
      setIsUserMenuOpen(false);
    }
  };

  useEffect(() => {
    if (!userState.data?.isActive && cookie.webAuthn) {
      getPublicKeyForCurrOrg();
    }
  }, []);

  return (
    <>
      <div className='Header'>
        {isUnpaidInvoiceBarOpen && (
          <TopNagBar
            stripeInvoiceLink={unpaidInvoice?.stripe_invoice_link}
            barText='Your invoices are not paid!'
            buttonName='Pay Invoice'
          />
        )}
        <div className='Header--center'>
          <img
            className='Header--logo'
            style={{ cursor: 'pointer' }}
            src={logo}
            alt='dStor'
            height='23px'
            width='77px'
            onClick={(): void => {
              if (setSelectedPage) {
                setSelectedPage(1);
              }
              navigate(userState.data?.loggedIn ? '/files' : '/');
            }}
          />
          {userState.data?.loggedIn ? (
            <div className='Header--center-nav'>
              <input
                type='text'
                placeholder='Search files and hashes'
                value={searchText}
                onChange={(e): void => setSearchText(e.target.value)}
                onKeyDown={doSearch}
              />
              {userState.data?.canUpload && !userState.data?.isFolder && (
                <>
                  <button onClick={(): void => setIsUploadOpen(true)}>
                    Upload
                  </button>
                  <button onClick={(): void => setIsNewDirectoryOpen(true)}>
                    New Directory
                  </button>
                </>
              )}
            </div>
          ) : (
            <TopNav />
          )}

          {userState.data?.loggedIn ? (
            <div className='Header--pulse-grid'>
              <Pulse />
              <UserMenu
                name={userState.data?.name}
                subName={menuName}
                isOpen={isUserMenuOpen}
                setIsOpen={setIsUserMenuOpen}
              >
                <ul className='Header--menu'>
                  <li
                    onClick={(): void => {
                      handleUserMenuClick('/files');
                    }}
                  >
                    Files
                  </li>
                  <li
                    onClick={(): void => {
                      handleUserMenuClick('/account/security');
                    }}
                  >
                    Account
                  </li>
                  {isBillingInfoAllowed && (
                    <li
                      onClick={(): any => handleUserMenuClick('/billing/usage')}
                    >
                      Billing
                    </li>
                  )}
                  {userState.data?.organizations &&
                    userState.data.accountKey &&
                    userState.data?.organizations.length > 0 && (
                      <li onClick={(): any => setIsOrgMenuOpen(!isOrgMenuOpen)}>
                        Organizations
                        {isOrgMenuOpen && (
                          <ul className='Header--menu-sub'>
                            <li
                              key={userState.data.accountKey}
                              onClick={async (): Promise<void> =>
                                await handleOrganizationSelect(
                                  userState?.data?.accountKey || '',
                                  true
                                )
                              }
                            >
                              Private account
                            </li>
                            {userState.data?.organizations.map((org: any) => (
                              <li
                                key={org.organization_data?.account_key || ''}
                                onClick={async (): Promise<void> =>
                                  await handleOrganizationSelect(
                                    org.organization_data?.account_key || '',
                                    false
                                  )
                                }
                              >
                                {org.organization_data?.owner_name || ''}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    )}
                  <li onClick={signOut}>Sign Out</li>
                </ul>
              </UserMenu>
            </div>
          ) : (
            <SignIn />
          )}
        </div>
      </div>
      <SideMenu
        isOpen={isDirectoryOpen}
        setIsOpen={setIsNewDirectoryOpen}
        position='CENTER'
      >
        <div className='Header--newfolder'>
          <Heading
            title='New Directory'
            subtitle='A directory does not create a hash and is used for organising files and folders. The contents can be edited.'
          />
          <ServerError error={error} />
          <TextInput
            type='text'
            name='Directory Name'
            value={directoryName}
            label
            setValue={setDirectoryName}
            focus
          />
          <TextInput
            type='text'
            name='Description'
            value={directoryDescription}
            label
            setValue={setDirectoryDescription}
            optional
          />
          <Button
            name='Create Directory'
            click={createFolder}
            loading={loading}
            disabled={disabled}
          />
        </div>
      </SideMenu>
      <SideMenu
        isOpen={isUploadOpen}
        setIsOpen={setIsUploadOpen}
        position='CENTER'
      >
        <>
          {!uploading && (
            <div className='Header--upload'>
              <Heading
                title='Upload'
                subtitle={
                  cardOnFile
                    ? 'Do you want to upload a file or a folder?'
                    : 'Oops! Before you upload!'
                }
              />
              {!cardOnFile && (
                <div>
                  Please add a valid credit card to your account. This ensures
                  smooth and uninterrupted service for you.
                  <a href='/billing/funding'> Add a Card</a>
                </div>
              )}
              {cardOnFile && (
                <>
                  <div
                    style={
                      userState.data?.encryption?.publicKey
                        ? {
                            display: 'grid',
                            gap: '24px',
                          }
                        : {}
                    }
                  >
                    <ServerError error={error} />
                    <FileInput setFiles={doUpload} multiple>
                      <div className='Header--upload-btn'>
                        <h2 className='Header--upload-btn-title'>File</h2>
                        <p className='Header--upload-btn-desc'>
                          Upload a single or multiple files. A single hash for
                          each file will be created.
                        </p>
                      </div>
                    </FileInput>
                    {((userState.data?.isActive &&
                      userState.data?.encryption?.publicKey) ||
                      (userState.currentOrganization?.accountKey &&
                        userState.currentOrganization?.encryption
                          ?.publicKey)) && (
                      <FileInput setFiles={doUpload} encrypt multiple>
                        <div className='Header--upload-btn'>
                          <h2 className='Header--upload-btn-title'>
                            Encrypt File
                          </h2>
                          <p className='Header--upload-btn-desc'>
                            Upload and encrypt a single or multiple files. A
                            single hash and password for each file will be
                            created.
                          </p>
                        </div>
                      </FileInput>
                    )}
                  </div>
                  <div className='Header--upload-or'>
                    <span>or</span>
                  </div>
                  <FileInput setFiles={doUpload} directory>
                    <div className='Header--upload-btn'>
                      <h2 className='Header--upload-btn-title'>Folder</h2>
                      <p className='Header--upload-btn-desc'>
                        Upload a folder along with its contents. Folders can not
                        be edited. the Folder will be addressable with a single
                        hash. <br />
                        <br />
                        The files within can be addressed by postfixing the file
                        name to the folders hash.
                      </p>
                    </div>
                  </FileInput>
                </>
              )}
            </div>
          )}
          {uploading && (
            <div className='Header--uploading'>
              <Heading
                title='Uploading'
                subtitle='Please be patient while your files are uploaded.'
              />
              {uploadError && (
                <ServerError
                  error={`${uploadError?.error} ${uploadError?.message}`}
                />
              )}
              <p>
                Uploading {filesToUpload} files in batches of {batchSize} chunks
              </p>
              <p>{batchesToUpload} batches left to upload.</p>
              <div className='Header--progress-bar'>
                {isProgressValid && (
                  <div
                    className='Header--progress'
                    style={{
                      width: `${progress}%`,
                    }}
                  ></div>
                )}
              </div>
            </div>
          )}
        </>
      </SideMenu>
      <SideMenu
        isOpen={isOpenExistingFilesModal}
        setIsOpen={setIsOpenExistingFilesModal}
        position='CENTER'
        width='REGULAR'
      >
        <div style={{ padding: '24px' }}>
          <Heading
            title={'Files Exist'}
            subtitle={
              'Files with these names already exist and have not been uploaded:'
            }
          />
          {existingFilesName.map((fileName: string) => (
            <li
              style={{ listStyleType: 'none', fontSize: '14px' }}
              id={fileName}
            >
              {fileName}
            </li>
          ))}
        </div>
      </SideMenu>
    </>
  );
};
export { Header };
