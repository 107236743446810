import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../components/Header';
import { FileBrowser } from '../components/FileBrowser';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';

function Files(): ReactElement {
  const navigate = useNavigate();
  const { userState } = useGlobalUserState();
  const [selectedPage, setSelectedPage] = useState<number>(
    parseInt(sessionStorage.getItem('currentPage') ?? '1', 10)
  );

  useEffect(() => {
    !userState.data?.loggedIn && navigate('/');
  }, []);
  return (
    <div className='Files'>
      <Header setSelectedPage={setSelectedPage} />
      <div className='Files--center'>
        <FileBrowser
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
    </div>
  );
}

export default Files;
