import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { callApi } from '../functions/callApi';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { useGlobalFilesState } from '../hooks/useGlobalFilesState';
import { FileShape } from '../interfaces/files';
import { FolderView } from './FolderView';
import { FileView } from './FileView';
import { ViewSelector } from './ViewSelector';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectInput } from './SelectInput';

export interface BreadcrumbShape {
  id: number;
  name: string;
  hash: string;
}

interface PagesObject {
  count: number;
  pagesArr: number[];
}

interface PropsShape {
  selectedPage: number;
  setSelectedPage: Dispatch<SetStateAction<number>>;
}

const MAX_LIMIT = 100;

const FileBrowser = ({
  selectedPage,
  setSelectedPage,
}: PropsShape): ReactElement => {
  const params = useParams();
  const navigate = useNavigate();
  const { userState, setUserState } = useGlobalUserState();
  const { filesState, setFilesState } = useGlobalFilesState();
  const [cleanFiles, setCleanFiles] = useState<FileShape[]>();
  const [cleanFolders, setCleanFolders] = useState<FileShape[]>();
  const [view, setView] = useState<'ROW' | 'SMALL' | 'LARGE'>(
    (localStorage.getItem('view') as 'ROW' | 'SMALL' | 'LARGE') || 'ROW'
  );
  const [breadcumbArr, setBreadcumbArr] = useState<BreadcrumbShape[]>([]);
  const [fileCount, setFileCount] = useState<number>();
  const [folderCount, setFolderCount] = useState<number>();
  const [offset, setOffset] = useState<number>(0);
  const [serverError, setServerError] = useState<string>('');
  const [pagesObject, setPagesObject] = useState<PagesObject>({
    count: 0,
    pagesArr: [],
  });

  useEffect(() => {
    if (view) {
      localStorage.setItem('view', view);
    }
  }, [view]);

  useEffect(() => {
    let isFolder: boolean = false;
    if (!breadcumbArr.length) isFolder = false;
    else isFolder = !!breadcumbArr[breadcumbArr.length - 1].hash;
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        isFolder,
      },
    }));
  }, [JSON.stringify(breadcumbArr)]);

  useEffect(() => {
    const handleScroll = (): void => {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      )
        return;
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          lastUpload: Date.now(),
        },
      }));
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const sortWithDirection = (property: any): any => {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a: any, b: any) {
      let aProp = a[property];
      let bProp = b[property];

      if (property === 'name') {
        const collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: 'base',
        });
        return collator.compare(aProp, bProp) * sortOrder;
      }

      if (property === 'created') {
        aProp = typeof aProp === 'string' ? Number(aProp) : aProp;
        bProp = typeof bProp === 'string' ? Number(bProp) : bProp;
      }

      const result = aProp < bProp ? -1 : aProp > bProp ? 1 : 0;
      return result * sortOrder;
    };
  };

  const sortBy = (sortBy: any): void => {
    const paramsArr = params['*']?.split('/') || [];
    const paramsKey = paramsArr[paramsArr.length - 1] || '';

    let fixedFolders = filesState.data?.filter((e: any) => {
      return e.folder === `/${paramsKey || ''}` && e.type === 'FOLDER';
    });
    let fixedFiles = filesState.data?.filter((e: any) => {
      return e.folder === `/${paramsKey || ''}` && e.type === 'FILE';
    });
    const fixedState = filesState?.data?.filter(
      (e: any) => e.folder !== `/${paramsKey || ''}`
    );

    if (fixedFolders && fixedFiles && fixedState) {
      if (userState.data?.sortBy) {
        fixedFolders = fixedFolders.sort(sortWithDirection(sortBy));
        fixedFiles = fixedFiles.sort(sortWithDirection(sortBy));
      }
      const tempFilesState: FileShape[] = [
        ...fixedState,
        ...fixedFolders,
        ...fixedFiles,
      ];

      setFilesState((prevState: any) => ({
        ...prevState,
        data: tempFilesState,
      }));
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          sortBy,
        },
      }));
    }
  };

  useEffect(() => {
    setServerError('');
    const paramsArr = params['*']?.split('/') || [];
    const paramsKey = paramsArr[paramsArr.length - 1] || '';
    const getDisplayedPages = (count: number): number[] => {
      const pagesToShow = 10;
      if (count <= pagesToShow) {
        return Array.from({ length: count }, (_, i) => i + 1);
      }
      const result = [];
      const startBuffer = 5;
      const endBuffer = 5;
      if (selectedPage <= startBuffer) {
        result.push(
          ...Array.from({ length: pagesToShow - 1 }, (_, i) => i + 1)
        );
        result.push(-1);
        result.push(count);
      } else if (selectedPage > count - endBuffer) {
        result.push(1);
        result.push(-1);
        result.push(
          ...Array.from(
            { length: pagesToShow - 1 },
            (_, i) => count - (pagesToShow - 2) + i
          )
        );
      } else {
        result.push(1);
        result.push(-1);
        const start = Math.max(selectedPage - startBuffer, 2);
        const end = Math.min(selectedPage + endBuffer, count - 1);
        result.push(
          ...Array.from({ length: end - start + 1 }, (_, i) => start + i)
        );
        if (end < count - 1) {
          result.push(-1);
        }
        result.push(count);
      }
      return result;
    };
    const getFiles = async (): Promise<void> => {
      let url = '';
      if (userState.data?.isActive) {
        url = `list/${userState.data?.accountKey}/`;
      } else if (userState.currentOrganization?.accountKey) {
        url = `list/${userState.currentOrganization?.accountKey}/` || '';
      } else {
        return;
      }
      if (paramsKey) {
        url += `${paramsKey}`;
      }

      const folders = await callApi<any>(`folders/${url}`);
      const files = await callApi<any>(
        `files/${url}?offset=${offset}&limit=${MAX_LIMIT}`
      );

      if (folders.status === 405 || files.status === 405) {
        setServerError(folders.message || files.message);
        return;
      }

      setPagesObject({
        count: Math.round(files.count / MAX_LIMIT),
        pagesArr: getDisplayedPages(Math.round(files.count / MAX_LIMIT)),
      });

      const fixFolders = (i: any): any => {
        const item: FileShape = {
          folder: `/${paramsKey || ''}`,
          type: 'FOLDER',
          created: i.created,
          hash: i.folder_hash,
          name: i.folder_name,
          key: i.key,
          description: i.comment,
        };
        return item;
      };
      const fixFiles = (i: any): any => {
        const item: FileShape = {
          folder: `/${paramsKey || ''}`,
          type: 'FILE',
          created: i.created,
          hash: i.file_hash,
          name: i.file_name,
          size: i.file_size,
          key: i.key,
          encrypted: i.encrypted,
          shared: i.shared,
        };
        return item;
      };

      let fixedFolders = folders?.results?.map(fixFolders);
      let fixedFiles = files?.results?.map(fixFiles);

      fixedFolders = fixedFolders.filter((e: any) => e !== undefined);
      fixedFiles = fixedFiles.filter((e: any) => e !== undefined);

      if (userState.data?.sortBy) {
        fixedFolders = fixedFolders.sort(
          sortWithDirection(userState.data?.sortBy)
        );
        fixedFiles = fixedFiles.sort(sortWithDirection(userState.data?.sortBy));
      }

      setFolderCount(fixedFolders.length);
      setFileCount(fixedFiles.length);

      const fixedState = filesState?.data?.filter(
        (e: any) => e.folder !== `/${paramsKey || ''}`
      );

      if (filesState.data?.length && fixedState) {
        const tempFilesState: FileShape[] = [
          ...fixedState,
          ...fixedFolders,
          ...fixedFiles,
        ];
        setFilesState((prevState: any) => ({
          ...prevState,
          data: tempFilesState,
        }));
      } else {
        setFilesState({ data: [...fixedFolders, ...fixedFiles] });
      }
    };
    getFiles();

    if (paramsKey && paramsArr) {
      const getNamesFromId = (arg: any): any => {
        const id = +arg;
        const name = filesState?.data?.filter((e: any) => e.key === id)[0]
          ?.name;

        const hash = filesState?.data?.filter((e: any) => e.key === id)[0]
          ?.hash;
        return { id, name, hash };
      };

      const tempBreadcumbArr = paramsArr?.map(getNamesFromId);
      setBreadcumbArr(tempBreadcumbArr);
    } else {
      setBreadcumbArr([]);
    }
  }, [
    params['*'],
    userState.data?.lastUpload,
    userState.data?.isActive,
    userState.currentOrganization?.accountKey,
    offset,
  ]);

  useEffect(() => {
    const files = filesState.data?.filter((e: any) => e.type == 'FILE');
    const folders = filesState.data?.filter((e: any) => e.type == 'FOLDER');
    setCleanFiles(files);
    setCleanFolders(folders);
  }, [filesState]);

  useEffect(() => {
    if (selectedPage > 0) {
      sessionStorage.setItem('currentPage', String(selectedPage));
      setOffset(selectedPage * MAX_LIMIT - MAX_LIMIT);
    }
  }, [selectedPage]);

  const calcLayout = (): string => {
    return view === 'ROW' ? 'FileBrowser--grid-row' : 'FileBrowser--grid';
  };

  const filterFolders = (): ReactElement => {
    const paramsArr = params['*']?.split('/') || [];
    const paramsKey = paramsArr[paramsArr.length - 1] || '';
    const filtered = cleanFolders?.filter(
      (folder) => folder.folder === `/${paramsKey || ''}`
    );
    return (
      <>
        {filtered?.map((folder, index) => (
          <div key={index}>
            <FolderView data={folder} setSelectedPage={setSelectedPage} />
          </div>
        ))}
      </>
    );
  };

  const filterFiles = (): ReactElement => {
    const paramsArr = params['*']?.split('/') || [];
    const paramsKey = paramsArr[paramsArr.length - 1] || '';
    const filtered = cleanFiles?.filter(
      (file) => file.folder === `/${paramsKey || ''}`
    );
    return (
      <>
        {filtered && filtered?.length > 0 && (
          <div className='FileBrowser--view-control'>
            <div />
            <ViewSelector setView={setView} selected={view || 'ROW'} />
          </div>
        )}

        <div className={calcLayout()}>
          {filtered?.map((file, index) => (
            <div key={index}>
              <FileView data={file} layout={view} folders={cleanFolders} />
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      {serverError ? (
        <div className='FileBrowser--empty'>
          <div>{serverError}</div>
        </div>
      ) : (
        <>
          <div className='FileBrowser'>
            <div className='FileBrowser--breadcrumbs'>
              <div
                className='FileBrowser--breadcrumbs-root'
                onClick={(): void => {
                  setSelectedPage(1);
                  navigate('/files');
                }}
              >
                All Files
              </div>
              <div className='FileBrowser--breadcrumbs-path'>
                {breadcumbArr?.map(function (item, i) {
                  return (
                    <div
                      className='FileBrowser--breadcrumbs-item'
                      key={i}
                      onClick={(): void => {
                        setSelectedPage(1);
                        let url = '';
                        for (let x = 0; x < i + 1; x++) {
                          if (x > 0) url += '/';
                          url += breadcumbArr[x].id;
                        }
                        navigate(`/files/${url}`);
                      }}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>

              <SelectInput
                name='Sort by'
                value={userState.data?.sortBy || 'name'}
                setValue={sortBy}
                options={[
                  { value: 'name', name: 'Name A-Z' },
                  { value: '-name', name: 'Name Z-A' },
                  { value: '-created', name: 'Newest to Oldest' },
                  { value: 'created', name: 'Oldest to Newest' },
                ]}
              />
            </div>
          </div>
          <div className={'FileBrowser--grid'}>{filterFolders()}</div>
          <div>{filterFiles()}</div>
          <div className={'FileBrowser--select-page'}>
            <div
              className={'FileBrowser--select-page-chevron'}
              style={{
                transform: 'rotate(180deg)',
              }}
              onClick={(): void => {
                const newPage = selectedPage === 1 ? 1 : selectedPage - 1;
                setSelectedPage(newPage);
              }}
            />
            {pagesObject.pagesArr.length > 0 &&
              pagesObject.pagesArr.map((page) =>
                page === -1 ? (
                  <div>...</div>
                ) : (
                  <div
                    key={page}
                    className='FileBrowser--select-page-number'
                    style={{
                      color: selectedPage === page ? '#2581e3' : '#373d48',
                    }}
                    onClick={(): void => setSelectedPage(page)}
                  >
                    {page}
                  </div>
                )
              )}
            <div
              className={'FileBrowser--select-page-chevron'}
              onClick={(): void => {
                const newPage =
                  selectedPage === pagesObject.count
                    ? pagesObject.count
                    : selectedPage + 1;
                setSelectedPage(newPage);
              }}
            />
          </div>
          {fileCount === 0 && folderCount === 0 && (
            <div className='FileBrowser--empty'>
              <div>There is nothing to see here, move along! </div>
              <div>
                Click <b>Upload</b> or <b>New Directory</b> above.
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export { FileBrowser };
